const axios = require('axios');
import { cloud_api_routes } from '../routes/cloud_api_routes.js';
let cloudAPI = process.env.VUE_APP_CLOUD_API;
import { setFlaskSessionActivity, checkFlaskPodAsleep, setFlaskPodWebSocket } from "../services/FlaskService";
import { global_config } from "@/config/config.js";
import store from "@/store";

export async function validateUseragentAndIP(params) {
    return await axios.get(`${cloudAPI}${cloud_api_routes.KIOSK_VALIDATE_USERAGENTIP}`, { params })
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function validateKioskPodId(pod_id) {
    const requestOptions = {
        pod_id: pod_id,
    };

    return await axios.post(`${cloudAPI}${cloud_api_routes.KIOSK_VALIDATE_POD_ID}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function userLogin(session_id, username, password) {
    const requestOptions = {
        session_id_key: session_id,
        username: username,
        password: password
    };

    return await axios.post(`${cloudAPI}${cloud_api_routes.USER_LOGIN}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function userMobileLogin(session_id, username, password) {
    const requestOptions = {
        session_id_key: session_id,
        username: username,
        password: password
    };

    return await axios.post(`${cloudAPI}${cloud_api_routes.USER_MOBILE_LOGIN}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function validateRemoteQR(qrcode, type="") {
    let params = {
        qr: qrcode,
        type: type,
    };

    return await axios.get(`${cloudAPI}${cloud_api_routes.KIOSK_VALIDATE_REMOTE_ID}`, { params })
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function userRegister(data) {
    const requestOptions = data;

    return await axios.post(`${cloudAPI}${cloud_api_routes.USER_REGISTER}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function userLogout(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.USER_LOGOUT}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function userExpireSessions(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.USER_EXPIRE_SESSIONS}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function userCheckEmail(data) {
    const requestOptions = data;

    return await axios.post(`${cloudAPI}${cloud_api_routes.USER_CHECK_EMAIL}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function getClientIP() {
    return await axios.get('https://api.ipify.org/?format=json')
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function sessionActivitySave(data) {
    localStorage.setItem('last_activity', new Date());
    let data_pre = JSON.parse(JSON.stringify(data));
    let pod_idle = localStorage.getItem('pod_idle');
    // check if there is an active session
    if(typeof localStorage.pod_sess_data !== 'undefined') {
        // update activity in localstorage first for later use
        console.log(data.activity, " <====> " , global_config.ACTIVITIES.IDLE, " , ", global_config.ACTIVITIES.ASLEEP);
        if(data.activity !== global_config.IDLE && data.activity !== global_config.ASLEEP) {
            localStorage.setItem('last_activity_data', JSON.stringify(data_pre));
        }
        // now check for pod idle and see if exists
        return await checkFlaskPodAsleep().then(pa => {
            // send cloud api request
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            data.session_id_key = pod_data.session_id_key;
            data.pod_id_key = pod_data.pod_id_key;
            
            if (Object.prototype.hasOwnProperty.call(pa, "success") && pa.success) {
                localStorage.setItem('pod_asleep', 'true');
                localStorage.removeItem('pod_idle');
                data.activity = global_config.ACTIVITIES.ASLEEP; 
                // call flask activity
                recordFlaskActivity(data);  

                return axios.post(`${cloudAPI}${cloud_api_routes.SESSION_SAVE}`, data)
                    .then(response => {
                        setFlaskPodWebSocket().then(sfpw => {
                            sfpw;
                        });
                        return response.data;
                    })
                    .catch(error => {
                        console.log(error.response);
                        return error.response;
                    });
            }
            else {
                // return checkFlaskPodIdle().then(fpi => {
                //     if (Object.prototype.hasOwnProperty.call(fpi, "success") && fpi.success) {
                //         localStorage.setItem('pod_idle', 'true');
                //         localStorage.removeItem('pod_asleep');
                //         data.activity = global_config.ACTIVITIES.IDLE;   
                //     }
                //     else {
                //         localStorage.removeItem('pod_asleep');
                //         localStorage.removeItem('pod_idle');
                //         localStorage.setItem('last_activity_data', JSON.stringify(data_pre));
                //     }
                //     // call flask activity
                //     recordFlaskActivity(data);

                //     return axios.post(`${cloudAPI}${cloud_api_routes.SESSION_SAVE}`, data)
                //         .then(response => response.data)
                //         .catch(error => {
                //             console.log(error.response);
                //             return error.response;
                //         });
                // });

                if(typeof pod_idle !== 'undefined' && pod_idle == 'true') {
                    store.commit("setIdleFlag", true);
                    localStorage.setItem('pod_idle', 'true');
                    localStorage.removeItem('pod_asleep');
                    data.activity = global_config.ACTIVITIES.IDLE;
                }
                else {
                    localStorage.removeItem('pod_asleep');
                    localStorage.removeItem('pod_idle');

                    // check if host is logged in
                    if(typeof localStorage.user_sess_data !== 'undefined') {
                        let user_data = JSON.parse(localStorage.user_sess_data);
                        if(
                            Object.prototype.hasOwnProperty.call(user_data, 'is_host') && 
                            user_data.is_host == 'Yes'
                        ) {
                            if(data.activity == global_config.ACTIVITIES.UI_EVENTPLAY) {
                                data_pre.activity = global_config.ACTIVITIES.UI_EVENTPLAY;
                            }
                            else {
                                data.activity = global_config.ACTIVITIES.UI_HOST;
                                data_pre.activity = global_config.ACTIVITIES.UI_HOST;
                            }
                        }
                    }
                    localStorage.setItem('last_activity_data', JSON.stringify(data_pre));
                }
                
                // call flask activity
                recordFlaskActivity(data);

                return axios.post(`${cloudAPI}${cloud_api_routes.SESSION_SAVE}`, data)
                .then(response => {
                    setFlaskPodWebSocket().then(sfpw => {
                        sfpw;
                    });
                    return response.data;
                })
                .catch(error => {
                    console.log(error.response);
                    return error.response;
                });
            }
        });
    }
    return null;
}

export async function recordFlaskActivity(data) {
    // send request to flask server
    let params = {
        activity: data.activity
    };
    setFlaskSessionActivity(params).then(sa => {
        console.log("FLASK ACTIVITY : ", sa);
    });

    // loging web activity on cloud server
    axios.post(`${cloudAPI}${cloud_api_routes.SESSION_FLASK}`, {resp: {'request': data}})
        .then(response => response.data)
        .catch(error => {
            console.log(error.response);
            return error.response;
        });
    return;
}

export async function getSystemMessagesFE() {
    return await axios.get(`${cloudAPI}${cloud_api_routes.SYSTEM_MESSAGES_GET}`)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function userUnlock(session_id, username, password) {
    const requestOptions = {
        session_id_key: session_id,
        username: username,
        password: password
    };

    return await axios.post(`${cloudAPI}${cloud_api_routes.USER_UNLOCK}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function userOrientation(params) {
    return await axios.post(`${cloudAPI}${cloud_api_routes.ORIENTATION_MARK}`, params)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function sessionActivityUpdate() {
    // get last activity so we could have the details to udpate
    let last_activity = localStorage.getItem('last_activity_data');
    if(typeof last_activity !== 'undefined' && last_activity !== 'null' && typeof localStorage.pod_sess_data !== 'undefined') {
        let last_activity_data = JSON.parse(last_activity);
        let pod_asleep = localStorage.getItem('pod_asleep');
        let pod_idle = localStorage.getItem('pod_idle');
        let pod_data = JSON.parse(localStorage.pod_sess_data);
        last_activity_data.session_id_key = pod_data.session_id_key;
        last_activity_data.pod_id_key = pod_data.pod_id_key;
        // check if asleep is there
        if(typeof pod_asleep !== 'undefined' && pod_asleep == 'true') {
            last_activity_data.activity = global_config.ACTIVITIES.ASLEEP;
        }
        else if(typeof pod_idle !== 'undefined' && pod_idle == 'true') {
            last_activity_data.activity = global_config.ACTIVITIES.IDLE;
        }
        return axios.post(`${cloudAPI}${cloud_api_routes.SESSION_UPDATE_ACTIVITY}`, last_activity_data)
        .then(response => {
            setFlaskPodWebSocket().then(sfpw => {
                sfpw;
            });
            return response.data;
        })
        .catch(error => {
            console.log(error.response);
            return error.response;
        });
    }
    return null;
}

export async function userAdminLogin(session_id, user_id) {
    const requestOptions = {
        session_id_key: session_id,
        user_id: user_id
    };

    return await axios.post(`${cloudAPI}${cloud_api_routes.USER_ADMIN_LOGIN}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function podUserLogin(username, pod_id) {
    const requestOptions = {
        username: username,
        pod_id: pod_id
    };

    return await axios.post(`${cloudAPI}${cloud_api_routes.POD_USER_LOGIN}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        return error.response;
    });
}

export async function userHostLogin(session_id, user_id) {
    const requestOptions = {
        session_id_key: session_id,
        user_id: user_id
    };

    return await axios.post(`${cloudAPI}${cloud_api_routes.USER_HOST_LOGIN}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function getGamesImagesList(params) {
    const requestOptions = params;
    return await axios.post(`${cloudAPI}${cloud_api_routes.PLAYER_GAMES_IMAGES_LIST}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function verify2FACode(params) {
    const requestOptions = params;

    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_VERIFY_2FA}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}

export async function userAdminModeLogin(session_id) {
    const requestOptions = {
        session_id_key: session_id
    };

    return await axios.post(`${cloudAPI}${cloud_api_routes.ADMIN_LOGIN_LAB_MODE}`, requestOptions)
    .then(response => response.data)
    .catch(error => {
        console.log(error.response);
        return error.response;
    });
}