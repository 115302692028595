<template>
    <div class="main-layout-section admin-portal">
        <SuperAdminHeader />
        
        <div class="w-logo mb-3">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" />
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Client Pods</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link :to="`/superadmin/clients/${client_id}`" class="admin-general-section-button">
                            Back To Client Details
                        </router-link>
                    </div>

                    <div class="col-sm-12">
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="pods"
                            :fields="podfields"
                            show-empty
                            :emptyText="emptyPods"
                            >
                                <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                                <template #cell(online)="data">
                                    <i class="fas fa-circle-check fa-2xl icon h2" v-if="data.item.pod.OnlineDetail.online == 1" v-bind:id="data.item.pod_id_key + '-tooltip'" style="color: #1ba10d;"></i>
                                    <i class="fas fa-times-circle fa-2xl icon h2" v-if="data.item.pod.OnlineDetail.online !== 1" style="color:#FF1744;" v-bind:id="data.item.pod_id_key + '-tooltip'"></i>
                                    <b-tooltip v-bind:target="data.item.pod_id_key + '-tooltip'" triggers="hover" placement="top">
                                        {{ data.item.pod.OnlineDetail.message }}
                                    </b-tooltip>
                                </template>
                                <template #cell(thumbnail)="data">
                                    <template v-if="data.item.pod.ScreenshotDetail.age >= 0 && data.item.pod.ScreenshotDetail.age <= 60">
                                        <img :src="`${data.item.pod.ScreenshotDetail.url}/${data.item.pod.ScreenshotDetail.image}`" style="height: 45px;border-radius: 4px;" @click="screenShotModal(data.item.pod_id_key, data.item.pod.ScreenshotDetail)" />
                                    </template>
                                    <template v-else>
                                        <span>{{ data.item.pod.ScreenshotDetail.message }}</span>
                                    </template>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->

        <!-- start of screenshot modal -->
        <b-modal id="superadmin-screenshot-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Pod Thumbnail
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <div class="d-flex" v-if="screenshot_pod_id !== ''">
                <template v-if="screenshot_pod.age >= 0 && screenshot_pod.age <= 60">
                    <img :src="`${screenshot_pod.url}/${screenshot_pod.image}?v=${(new Date()).now}`" style="    width: 100%;max-height: 400px;">
                </template>
                <template v-else>
                    <span>{{ screenshot_pod.message }}</span>
                </template>
            </div>
        </b-modal>
    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import { adminGetPodsList, adminGetPodScreenshot } from "../../services/AdminService";

export default {
  name: "SuperAdminClientsPods-view",
  components: { SuperAdminHeader, SuperAdminFooter },
  data() {
    return {
      client_id: this.$route.params.client_id,
      lab_id: this.$route.params.lab_id,
      emptyPods:"No pods found for requestd lab!",
      podfields: [
        {'key' : 'id', 'label' : 'ID'}, 
        {'key' : 'value', 'label' : 'Key'},
        {'key' : 'nav_id', 'label' : 'Nav ID'}, 
        {'key' : 'seat', 'label' : 'Seat'}, 
        {'key' : 'status', 'label' : 'Status'},
        {'key' : 'online', 'label' : 'Online'},
        {'key' : 'thumbnail', 'label' : 'Thumbnail'},
      ],
      pods: [],
      pods_poller: null,
      pods_api_state: false,
      screenshot_pod_id: "",
      screenshot_pod: {},
      screenshot_api_state: false,
      screenshot_api_poller: null,
    };
  },

  computed: {
  },

  methods: {
    podsList() {
        if(this.pods_api_state) {
            return;
        }
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            client_id: this.client_id,
            lab_id: this.lab_id
        };
        this.pods_api_state = true;
        adminGetPodsList(params).then(response => {
            this.pods_api_state = false;
            this.pods = response.data.pods;
        });
    },

    makeToast(variant = "default", message) {
      this.$toast.open({
        message: message,
        type: variant,
        position: 'top-right',
        duration: 3000,
        dismissible: true
      });
    },

    screenShotModal(pod_id, screenshot) {
      this.screenshot_pod_id = pod_id;
      this.screenshot_pod = screenshot;
      this.$bvModal.show('superadmin-screenshot-modal');
      clearInterval(this.screenshot_api_poller);
      this.screenshot_api_poller = setInterval(() => {
        this.handlePodScreenshot();
      }, 8000);
    },
    handlePodScreenshot() {
        if(this.screenshot_pod_id !== "" && !this.screenshot_api_state) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                pod_id: this.screenshot_pod_id
            };
            this.screenshot_api_state = true;
            adminGetPodScreenshot(params).then(response => {
                this.screenshot_api_state = false;
                if(response.status) {
                    this.screenshot_pod = response.data.screenshot;
                    if(this.pods.length > 0) {
                        let _self = this;
                        this.pods.map(function(lb){
                            if(lb.pod_id_key == _self.screenshot_pod_id) {
                                return lb.pod.ScreenshotDetail = _self.screenshot_pod;
                            }
                            else {
                                return lb;
                            }
                        });
                    }
                }
            });
        }
    },
  },

  mounted() {
    this.podsList();
    // check for pods list poller
    clearInterval(this.pods_poller);
    this.pods_poller = setInterval(() => {
        this.podsList();
    }, process.env.VUE_APP_PODS_LIST_DURATION);

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId == "superadmin-screenshot-modal") {
        this.screenshot_pod_id = "";
        this.screenshot_pod = {};
        clearInterval(this.screenshot_api_poller);
        this.screenshot_api_state = false;
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.pods_poller);
    // clear screenshot interval variable
    clearInterval(this.screenshot_api_poller);
  }

};
</script>