<template>
  <div class="main-layout-section player-logout-page">
    <div class="main-header main-header-player" id="main-header-player"></div>
    <div class="smaller-screen-header">
        <div class="smaller-screen-header-logo">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"
                v-if="logo_file !== '' && logo_file !== null"
            />
        </div>
        <div v-if="nav_name !== ''" class="w-form-control pod-info-nav animate__animated animate__fadeInUp animate__delay-1s smaller-screen-header-nav">
            <span class="pod-info-nav-name">
                {{ nav_name }}
            </span>
        </div>
    </div>

    <div class="w-logo mb-5 pt-5 larger-screen-header">
        <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
    </div>

    <div class="main-content">
        <template v-if="!is_survey">
            <h2 class="page-heading animate__animated animate__fadeIn animate__delay-1s">Thanks for playing</h2>
            <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__delay-1s">Setting up for the next player...</h3>

            <!-- start of buttons section -->
            <div class="dashboard-buttons-container col-md-3 animate__animated animate__fadeIn animate__delay-2s">
                    <div class="logout-loading-image mx-auto">
                        <object type="image/svg+xml" :data="require(`../../assets/images/gamelab-bits.svg`)"></object>
                    </div>
                    <div class="logout-reason mt-70">
                        <p class="text-center">Logout reason : {{ logout_reason }}</p>
                    </div>
                    <div class="logout-note d-none">
                        <p>If you think you were logged out incorrectly or if this loading screen remains for more than 10 seconds, please chat with us here or on the homepage and let us know what happened</p>
                    </div>
            </div>
            <!-- end of buttons section -->
        </template>

        <template v-if="is_survey">
            <ExitSurveyWidget :session_data="session_data" @surveyComplete="surveyComplete($event)" />
        </template>
    </div>


    <!-- start of footer section -->
    <div class="col-md-12 mx-auto">
        <PlayerFooter type="inner" class="animate__animated animate__fadeIn animate__delay-3s" />
    </div>
    <!-- end of footer section -->

  </div>
</template>

<script>
import store from "@/store";
import { postReadyPod, postReadyWeb, setFlaskSSOLogout, setFlaskPodWebReset, deleteFlaskPodUser } from "../../services/FlaskService";
import { global_config } from "@/config/config.js";
import PlayerFooter from "@/views/player/PlayerFooter.vue";
import { sessionActivitySave } from "../../services/HomepageService";
import { handleDocumentHeight } from "../../services/Guard";
import ExitSurveyWidget from "@/components/ExitSurveyWidget.vue";

export default {
    name: "PlayerLogout-view",
    components: { PlayerFooter, ExitSurveyWidget },
    data() {
        return {
            is_ready_pod: false,
            ready_polling: null,
            logout_reason: '',
            session_data: null,
            user_data: null,
            is_survey: false,
        };
    },

    computed: {
        getMessage() {
            return store.getters.getMessage;
        },
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        },
        nav_name() {
            return this.$store.getters.getNavName();
        }
    },

    methods: {
        sendReadyWeb() {
            postReadyWeb().then(response => {
                if(response.success !== undefined){
                    setTimeout(() => {
                        this.pollFlaskReadyPod();
                    }, 3000);
                }
            })
        },

        setPodData(obj) {
            this[obj.key] = obj.value;
        },

        pollFlaskReadyPod () {
            this.ready_polling = setInterval(() => {
                // get xd types for buttons
                postReadyPod().then(prw => {
                    if(Object.prototype.hasOwnProperty.call(prw, "success") && (prw.success == 'True' || prw.success == true) ) {
                        if(typeof localStorage.pod_sess_data !== 'undefined') {
                            let pod_sess_data = JSON.parse(localStorage.pod_sess_data);
                            if(Object.prototype.hasOwnProperty.call(pod_sess_data, 'sso_active') && pod_sess_data.sso_active == 1) {
                                setFlaskSSOLogout().then(sso_log => {
                                    sso_log; 
                                });
                            }
                        }
                        if(typeof localStorage.pod_sess_data !== 'undefined') {
                            let pod_sess_data = JSON.parse(localStorage.pod_sess_data);
                            if(Object.prototype.hasOwnProperty.call(pod_sess_data, 'sso_active') && pod_sess_data.sso_active == 1) {
                                setFlaskPodWebReset().then(sso_reset => {
                                    sso_reset; 
                                });
                            }
                        }

                        // delete pod user file
                        deleteFlaskPodUser().then((dpu) => {
                            dpu;
                        });
                        
                        localStorage.removeItem('qr_sess_data');
                        localStorage.removeItem('user_sess_data');
                        localStorage.removeItem('pod_sess_data');
                        localStorage.removeItem('pod_idle');
                        localStorage.removeItem('pod_asleep');
                        localStorage.removeItem('last_activity_data');
                        localStorage.removeItem('save_seat');
                        this.$root.$emit('live-chat-widget', 'logged-out');
                        this.is_ready_pod = prw.success;
                        document.getElementsByClassName('w-logo')[0].classList.add('animate__animated');
                        document.getElementsByClassName('w-logo')[0].classList.add('animate__fadeOut');
                        document.getElementsByClassName('main-content')[0].classList.add('animate__animated');
                        document.getElementsByClassName('main-content')[0].classList.add('animate__fadeOut');
                        setTimeout(() => {
                            this.$router.push({ path: '/' });
                        }, 1500);
                    }
                });
            }, 2000);
        },
        setUserData() {
            // check if survey needed or not
            let params = this.$route.params;
            if(typeof localStorage.pod_sess_data !== 'undefined' && typeof localStorage.user_sess_data !== 'undefined') {
                this.session_data = JSON.parse(localStorage.pod_sess_data);
                this.user_data = JSON.parse(localStorage.user_sess_data);
                if(Object.prototype.hasOwnProperty.call(params, "survey_needed") && params.survey_needed && Object.prototype.hasOwnProperty.call(this.session_data, 'session_id_key') && Object.prototype.hasOwnProperty.call(this.user_data, 'role_id') && this.user_data.role_id == 1) {
                    this.is_survey = true;
                }
                else {
                    this.sendReadyWeb();
                }
            }
            else {
                // this.sendReadyWeb();
                localStorage.removeItem('qr_sess_data');
                localStorage.removeItem('user_sess_data');
                localStorage.removeItem('pod_sess_data');
                localStorage.removeItem('pod_idle');
                localStorage.removeItem('pod_asleep');
                localStorage.removeItem('last_activity_data');
                localStorage.removeItem('save_seat');
                this.$root.$emit('live-chat-widget', 'logged-out');
                clearInterval(this.ready_polling);
                this.$router.push({ path: '/' });
                // document.getElementsByClassName('w-logo')[0].classList.add('animate__animated');
                // document.getElementsByClassName('w-logo')[0].classList.add('animate__fadeOut');
                // document.getElementsByClassName('main-content')[0].classList.add('animate__animated');
                // document.getElementsByClassName('main-content')[0].classList.add('animate__fadeOut');
                // setTimeout(() => {
                //     this.$router.push({ path: '/' });
                // }, 5000);
            }
        },
        surveyComplete() {
            this.is_survey = false;
            this.sendReadyWeb();
        }
    },

    mounted() {
        handleDocumentHeight();
        // call activity record api
        sessionActivitySave({'activity': global_config.ACTIVITIES.UI_LOGOUT}).then((response) => {
            console.log("ACTIVITY API CALLED : ", response);
        });
        let params = this.$route.params;
        if(Object.prototype.hasOwnProperty.call(params, "reason")) {
            this.logout_reason = params.reason;
        }
        // else {
        //     clearInterval(this.ready_polling);
        //     document.getElementsByClassName('w-logo')[0].classList.add('animate__animated');
        //     document.getElementsByClassName('w-logo')[0].classList.add('animate__fadeOut');
        //     document.getElementsByClassName('main-content')[0].classList.add('animate__animated');
        //     document.getElementsByClassName('main-content')[0].classList.add('animate__fadeOut');
        //     setTimeout(() => {
        //         this.$router.push({ path: '/' });
        //     }, 1500);
        // }
    },
    beforeDestroy () {
        clearInterval(this.ready_polling);
    },
    created () {
        // this.sendReadyWeb();
        this.setUserData();
    }
};
</script>